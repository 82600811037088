<template>
  <div class="page">
    <div class="item-box" v-for="(item, idx) in list" :key="idx">
      <!-- <img
        class="item-tips"
        v-if="item.showOrder == 1"
        src="@/assets/imgs/question/tips.png"
      /> -->
      <div class="item-content">
        <img class="content-img" :src="item.avatarUrl" />
        <div class="content-box">
          <div class="content-top">
            <div class="content-top__title">
              <div>{{ item.name }}</div>
              <span>{{ item.title }}</span>
            </div>
            <div class="content-top__btn" @click="onAsk(item)">
              发起提问
            </div>
          </div>
          <div class="content-bottom">
            <div class="bottom-title">专家介绍</div>
            <div class="bottom-content">{{ item.intro }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getExpertList } from "@/api/qa";
import to from "@/utils/to";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.$showLoading("加载中...");
      let [err, res] = await to(getExpertList());
      this.$hideLoading();

      if (err != null) {
        this.$toast.fail("加载错误");
        return;
      }
			
      this.list = res;
    },
    onAsk(item) {
      this.$router.push({
        name: "Ask",
        query: { desc: JSON.stringify(item) },
      });
    },
  },
};
</script>

<style scoped>
.page {
	height: calc(100vh - 32px);
	background: #f3f7fa;
	padding: 16px;
	display: flex;
	flex-direction: column;
}

.item-box {
	height: 150px;
	background: #ffffff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	display: flex;
	align-items: flex-start;
	margin-bottom: 12px;
}

.item-tips {
	width: 40px;
	height: 40px;
	position: relative;
	margin: -1px 0 0 -1px;
}

.item-content {
	position: absolute;
	padding: 20px 16px;
	display: flex;
	align-items: flex-start;
}

.content-img {
	width: 84px;
	height: 108px;
	margin-top: 2px;
}

.content-box {
	width: 220px;
	margin-left: 12px;
}

.content-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	color: #333333;
}

.content-top__title div {
	font-size: 18px;
	font-weight: 700;
	line-height: 25px;
	color: #333333;
	text-align: left;
}

.content-top__btn {
	padding: 4px 12px;
	background: #ffc83e;
	border-radius: 12px;
}

.content-bottom {
	background: #f3f7fa;
	border-radius: 4px;
	font-size: 12px;
	line-height: 17px;
	color: #999999;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 14px;
	padding-bottom: 12px;
}

.bottom-title {
	padding: 2px 8px 2px 4px;
	background: #ffc83e;
	border-radius: 4px 0px 12px 0px;
	font-size: 10px;
	line-height: 14px;
	color: #333333;
	display: flex;
	align-items: center;
}

.bottom-content {
	max-height: 20px;
	width: 200px;
	margin: 8px 0 0 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}
</style>

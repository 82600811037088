import { get } from './http.js'
import {postJson} from "./http";

/**
 * 获取专家列表
 */
export const getExpertList = () => get(`/qa/v1.0/experts`)

/**
 * 提交问题
 */
export const ask = (data) => {
  return postJson(`/qa/v1.0/ask`, data)
}

/**
 *  获取我的提问列表
 */
 export const getMyQaList = (isAnswer ,pageNum, pageSize) => {
  let params = {
    isAnswer,  
    pageNum,
    pageSize
  }
  return get('/qa/v1.0/mine', params)
}

/**
 * 提问详情
 * @returns 
 */
export const getQa = (id) => get(`/qa/v1.0/question/${id}`)

export const answer = (id, data) => postJson(`/qa/v1.0/answer/${id}`, data)